<template>
  <div class="modal-avatars-content">
    <div class="modal-avatars-content__description">
      {{ getContent(popupsData, defaultLocalePopupsData, 'avatars.chooseYourCharacter') }}
    </div>
    <div class="modal-avatars-content__items">
      <template v-if="isLoading">
        <div v-for="n in AVATARS_COUNT" :key="n" class="modal-avatars-content__item">
          <Skeletor class="modal-avatars-content__skeleton" as="div" />
        </div>
      </template>

      <template v-if="!isLoading && avatars?.length">
        <div
          v-for="avatar in avatars"
          class="modal-avatars-content__item"
          :key="avatar.id"
          @click="selectAvatar(avatar.id)"
        >
          <Skeletor class="modal-avatars-content__skeleton" as="div" v-if="!loadedAvatars.includes(avatar.id)" />

          <atomic-image
            notLazy
            class="img"
            :class="{ selected: avatar.id === selectedAvatarId || currentAvatar?.id === avatar.id }"
            :src="`${gamehubCdn}/${avatar.path}`"
            @imageLoaded="addLoadedAvatar(avatar.id)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const profileStore = useProfileStore();
  const { avatars, isLoggedIn } = storeToRefs(profileStore);
  const { getAvatarsData } = profileStore;

  const props = defineProps<{
    currentAvatarPath?: string;
  }>();

  const AVATARS_COUNT = 40;
  const isLoading = ref(false);

  const emit = defineEmits(['selectAvatar']);

  const globalStore = useGlobalStore();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(globalStore);
  const { getContent } = useProjectMethods();
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();

  const selectedAvatarId = ref('');
  const loadedAvatars = ref<Maybe<string>[]>([]);

  const addLoadedAvatar = (avatarId: string): void => {
    loadedAvatars.value.push(avatarId);
  };

  const currentAvatar = computed(() => {
    return avatars.value?.find(item => item.path === profileStore.profile?.avatar_url);
  });

  const selectAvatar = (avatarId: string): void => {
    if (currentAvatar.value?.id === avatarId) {
      selectedAvatarId.value = '';
    } else {
      selectedAvatarId.value = avatarId;
    }
    emit('selectAvatar', selectedAvatarId.value);
  };

  onMounted(async () => {
    if (isLoggedIn.value && !avatars?.value?.length) {
      isLoading.value = true;
      await getAvatarsData();
      await nextTick();
      isLoading.value = false;
    }
    selectedAvatarId.value = avatars?.value?.find(avatar => avatar.path === props.currentAvatarPath)?.id || '';
  });
</script>

<style src="~/assets/styles/components/modal-content/avatars.scss" lang="scss" />
